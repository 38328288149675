import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LoadingRoutingModule } from './loading-routing.module';
import { LoadingComponent } from './loading.component';


@NgModule({
  declarations: [
    LoadingComponent
  ],
  imports: [
    CommonModule,
    LoadingRoutingModule
  ],
  exports: [
    LoadingComponent
  ]
})
export class LoadingModule { }
