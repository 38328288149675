import { Component } from '@angular/core';
import { LayoutService } from '../../../../layout/service/app.layout.service';

@Component({
    selector: 'app-notfound',
    templateUrl: './notfound-web.component.html',
})
export class NotfoundWebComponent {

    constructor(public layoutService: LayoutService) {
     }
 }