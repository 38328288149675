import { Component } from '@angular/core';
import { WebLayoutService } from '../../services/web-layout.service';

@Component({
  selector: 'app-web-footer',
  templateUrl: './web-footer.component.html',
  styles: [
  ]
})
export class WebFooterComponent {
  constructor(public layoutService: WebLayoutService) { }
}