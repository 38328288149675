<div class="layout-topbar">
    <a class="layout-topbar-logo" routerLink="/web">
        <img src="assets/layout/images/{{layoutService.config.colorScheme === 'light' ? 'logo-developsoft' : 'logo-developsoft'}}.svg" 
        alt="DevelopSoft Logo"
        height="40" 
        class="mr-0 lg:mr-4">
        <h2 class="layout-topbar-menu text-900 font-medium line-height-3 mr-8">DevelopSoft</h2>
    </a>

    <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="layoutService.onMenuToggle()">
        <i class="pi pi-bars"></i>
    </button>

    <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button" (click)="layoutService.showProfileSidebar()">
        <i class="pi pi-ellipsis-v"></i>
    </button>

    <div #topbarmenu class="layout-topbar-menu" [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}">
        <div class="p-link content-user-left" (click)="onSidebarRightButtonClick()">
            
            <div class="user-data">
                <div class="user-data-name">
                    <span>{{ user?.name }}</span>
                </div>
                <div class="user-data-subcontent">
                    <span>{{ user?.profile }} - {{ user?.profile }}</span>
                </div>
            </div>
            <p-avatar class="p-link user-avatar" image="assets\demo\images\avatar\amyelsner.png" size="large"></p-avatar>
        </div>
        <!-- <button class="p-link layout-topbar-button">
            <i class="pi pi-calendar"></i>
            <span>Calendio</span>
        </button> -->
        <!-- <button class="p-link layout-topbar-button" (click)="op.toggle($event)">
            <i class="pi pi-bell"></i>
            <span>Notificaciones</span>
            <span id="notication_menu_num" data-action="cart-can" class="badge rounded-circle">5</span>
        </button>
        <p-overlayPanel #op>
            <app-notifications-popup></app-notifications-popup>
        </p-overlayPanel> -->

        <button class="p-link layout-topbar-button" (click)="onSidebarRightButtonClick()">
            <i class="pi pi-cog"></i>
            <span>Ajustes</span>
        </button>
        <button class="p-link layout-topbar-button-hidden" (click)="confirmlogout()">
            <i class="pi pi-sign-out"></i>
            <span>Salir</span>
        </button>
        <!-- <p-toast></p-toast>
        <p-confirmDialog #cd [style]="{width: '50vw'}">
            <ng-template pTemplate="footer">
                <button type="button" pButton icon="pi pi-check" label="Si" (click)="cd.accept()"></button>
                <button type="button" pButton icon="pi pi-times" label="No" (click)="cd.reject()"></button>
            </ng-template>
        </p-confirmDialog> -->
    </div>
</div>