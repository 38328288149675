import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';


interface MenuItem {
  label: string;
  icon?: string;
  routerLink?: string[];
  items?: MenuItem[];
  url?: string[];
  target?: string;
}

@Injectable({
  providedIn: 'root'
})
export class MenuRoutesService {

  public menuAdmin?: MenuItem[];
  public menuProvider?: MenuItem[];
  public menuCustomer?: MenuItem[];
  public menuUser?: MenuItem[];
  public menuGuest?: MenuItem[];

  constructor() { }

  createHeaderItem(label: string, items: MenuItem[]): MenuItem {
    return { label, items };
  }
  createSubHeaderItem(label: string, icon: string, items: MenuItem[]): MenuItem {
    return { label, icon, items };
  }
  createMenuItemExternal(label: string, icon?: string, url?: string[], target?: string): MenuItem {
    return { label, icon, url, target};
  }
  createMenuItem(label: string, icon?: string, routerLink?: string[], url?: string[], target?: string): MenuItem {
    return { label, icon, routerLink, url, target};
  }

  getMainMenuProfile(numberMenu: number){
    switch(numberMenu){
      case 1: 
        return this.menuAdmin = [
          this.createHeaderItem('Inicio', [
            this.createMenuItem('Panel', 'pi pi-fw pi-home', ['/web'])
          ]),
          this.createHeaderItem('Mantenedores', [
            this.createMenuItem('Mantenedor de Usuarios', 'pi pi-fw pi-cloud', ['/web/services/cloud']),
            this.createMenuItem('Mantenedor de Permisos', 'pi pi-fw pi-shopping-cart', ['/web/services/ecommerce']),
            this.createMenuItem('Mantenedor de Servicios', 'pi pi-fw pi-code', ['/web/services/hosting/apis']),
            this.createMenuItem('Mantenedor de Proyectos', 'pi pi-fw pi-code', ['/web/services/hosting/apis']),
          ]),
          this.createHeaderItem('Comunicación', [
            this.createMenuItem('Notificaciones', 'pi pi-fw pi-cloud', ['/web/services/cloud']),
            this.createMenuItem('Chat', 'pi pi-fw pi-cloud', ['/web/services/cloud']),
            this.createMenuItem('Contacto', 'pi pi-fw pi-cloud', ['/web/services/cloud']),
          ]),
          this.createHeaderItem('Soporte', [
            this.createMenuItem('Tickets', 'pi pi-fw pi-database', ['/web/services/hosting/data-base'])
          ])
        ];
      case 2:
        return this.menuProvider = [
          this.createHeaderItem('Inicio', [
            this.createMenuItem('Panel', 'pi pi-fw pi-home', ['/web'])
          ]),
          this.createHeaderItem('Administración', [
            this.createMenuItem('Mantenedor de Clientes', 'pi pi-fw pi-cloud', ['/web/services/cloud']),
            this.createMenuItem('Cotizaciones', 'pi pi-fw pi-cloud', ['/web/services/cloud']),
            this.createMenuItem('Servicios', 'pi pi-fw pi-shopping-cart', ['/web/services/ecommerce']),
            this.createMenuItem('Proyectos', 'pi pi-fw pi-code', ['/web/services/hosting/apis'])
          ]),
          this.createHeaderItem('Servicios', [
            this.createMenuItem('Panel', 'pi pi-fw pi-folder-open', ['/web/services/hosting/hosting']),
            this.createMenuItem('Hosting', 'pi pi-fw pi-server', ['/web/services/hosting/hosting']),
            this.createMenuItem('Software', 'pi pi-fw pi-folder-open', ['/projects/login']),
            this.createMenuItem('APIs', 'pi pi-fw pi-folder-open', ['/projects/login']),
            this.createMenuItem('Mantenciones', 'pi pi-fw pi-users', ['/projects/login']),
            this.createMenuItem('Cloud', 'pi pi-fw pi-share-alt', ['/projects/access']),
            this.createMenuItem('E-commerce', 'pi pi-fw pi-share-alt', ['/projects/access'])
          ]),
          this.createHeaderItem('Proyectos', [
            this.createMenuItem('Proyectos', 'pi pi-fw pi-folder-open', ['/projects/login']),
            this.createSubHeaderItem('Mis Proyectos', 'pi pi-fw pi-users', [
              this.createMenuItem('Panel', 'pi pi-fw pi-users', ['/projects/login']),
              this.createMenuItem('Equipo', 'pi pi-fw pi-cog', ['/projects/error']),
              this.createMenuItem('Publicaciones', 'pi pi-fw pi-share-alt', ['/projects/access'])
            ])
          ]),
          this.createHeaderItem('Contable', [
            this.createMenuItem('Facturación', 'pi pi-fw pi-users', ['/projects/login']),
            this.createMenuItem('Boletas', 'pi pi-fw pi-users', ['/projects/login']),
            this.createMenuItem('Pagos', 'pi pi-fw pi-users', ['/projects/login']),
          ]),
          this.createHeaderItem('Comunicación', [
            this.createMenuItem('Notificaciones', 'pi pi-fw pi-cloud', ['/web/services/cloud']),
            this.createMenuItem('Chat', 'pi pi-fw pi-cloud', ['/web/services/cloud']),
            this.createMenuItem('Contacto', 'pi pi-fw pi-cloud', ['/web/services/cloud']),
          ]),
          this.createHeaderItem('Soporte', [
            this.createMenuItem('Tickets', 'pi pi-fw pi-database', ['/web/services/hosting/data-base'])
          ])
        ];
      case 3:
        return this.menuCustomer = [
          this.createHeaderItem('Inicio', [
            this.createMenuItem('Dashboard', 'pi pi-fw pi-home', ['/web'])
          ]),
          this.createHeaderItem('Proyectos', [
            this.createMenuItem('MisProyectos', 'pi pi-fw pi-folder-open'),
            this.createMenuItem('Equipos de trabajo', 'pi pi-fw pi-users', ['/projects/login']),
            this.createMenuItem('Publicaciones', 'pi pi-fw pi-share-alt', ['/projects/access'])
          ]),
          this.createHeaderItem('Servicios', [
            this.createMenuItem('Panel de servicios', 'pi pi-fw pi-cloud', ['/web/services/dashboard-services']),
            this.createSubHeaderItem('Hosting', 'pi pi-fw pi-server', [
              this.createMenuItem('Estado de Servicio', 'pi pi-fw pi-server', ['/web/services/hosting/status-hosting']),
              this.createMenuItem('Información General', 'pi pi-fw pi-envelope', ['/web/services/hosting/email']),
              this.createMenuItem('Cliente', 'pi pi-fw pi-database', ['/web/services/hosting/data-base']),
              this.createMenuItem('Recursos de Hosting', 'pi pi-fw pi-envelope', ['/web/services/hosting/email']),
              this.createMenuItem('Registros y Eventos', 'pi pi-fw pi-server', ['/web/services/hosting/hosting']),
              this.createMenuItem('Gestión de Cuentas', 'pi pi-fw pi-database', ['/web/services/hosting/data-base']),
              this.createMenuItem('Soporte', 'pi pi-fw pi-envelope', ['/web/services/hosting/email']),
            ]),
            this.createMenuItem('Cloud', 'pi pi-fw pi-cloud', ['/web/services/cloud']),
            this.createMenuItem('E-commerce', 'pi pi-fw pi-shopping-cart', ['/web/services/ecommerce']),
            this.createMenuItem('APIs', 'pi pi-fw pi-code', ['/web/services/hosting/apis']),
            this.createMenuItem('Otros servicios', 'pi pi-fw pi-envelope', ['/web/services/hosting/email'])
          ]),
          this.createHeaderItem('Mis Software', [
            this.createMenuItem('Software', 'pi pi-fw pi-clone'),
            this.createMenuItemExternal('Automatizaciones', 'pi pi-fw pi-at', ['https://www.primefaces.org/primeflex/'], '_blank')
          ])
        ];
      case 4:
        return this.menuUser = [
          this.createHeaderItem('Home', [
            this.createMenuItem('Dashboard', 'pi pi-fw pi-home', ['/web'])
          ]),
          this.createHeaderItem('Servicios', [
            this.createMenuItem('Mis Servicios', 'pi pi-fw pi-cloud', ['/web/services/cloud']),
          ]),
          this.createHeaderItem('Proyectos', [
            this.createMenuItem('Mis Proyectos', 'pi pi-fw pi-folder-open'),
          ]),
        ];
      case 5:
        return this.menuGuest = [
          this.createHeaderItem('Home', [
            this.createMenuItem('Dashboard', 'pi pi-fw pi-home', ['/web'])
          ]),
          this.createHeaderItem('Servicios', [
            this.createMenuItem('Mis Servicios', 'pi pi-fw pi-cloud', ['/web/services/cloud']),
          ]),
          this.createHeaderItem('Proyectos', [
            this.createMenuItem('Mis Proyectos', 'pi pi-fw pi-folder-open'),
          ]),
        ];
      default:
        return this.menuProvider = [
          this.createHeaderItem('Home', [
            this.createMenuItem('Dashboard', 'pi pi-fw pi-home', ['/web'])
          ]),
          this.createHeaderItem('Servicios', [
            this.createSubHeaderItem('Servicios Hosting', 'pi pi-fw pi-server', [
              this.createMenuItem('Hosting', 'pi pi-fw pi-server', ['/web/services/hosting/hosting']),
              this.createMenuItem('Base de datos', 'pi pi-fw pi-database', ['/web/services/hosting/data-base']),
              this.createMenuItem('E-mail', 'pi pi-fw pi-envelope', ['/web/services/hosting/email'])
            ]),
            this.createMenuItem('Cloud', 'pi pi-fw pi-cloud', ['/web/services/cloud']),
            this.createMenuItem('E-commerce', 'pi pi-fw pi-shopping-cart', ['/web/services/ecommerce']),
            this.createMenuItem('APIs', 'pi pi-fw pi-code', ['/web/services/hosting/apis'])
          ]),
          this.createHeaderItem('Proyectos', [
            this.createMenuItem('Proyectos', 'pi pi-fw pi-folder-open'),
            this.createSubHeaderItem('Equipos', 'pi pi-fw pi-users', [
              this.createMenuItem('Grupos de trabajo', 'pi pi-fw pi-users', ['/projects/login']),
              this.createMenuItem('Ajustes', 'pi pi-fw pi-cog', ['/projects/error']),
              this.createMenuItem('Publicaciones', 'pi pi-fw pi-share-alt', ['/projects/access'])
            ])
          ]),
          this.createHeaderItem('Mis Software', [
            this.createMenuItem('Software', 'pi pi-fw pi-clone'),
            this.createMenuItemExternal('Automatizaciones', 'pi pi-fw pi-at', ['https://www.primefaces.org/primeflex/'], '_blank')
          ])
        ];
    }
  }
  getSecundaryMenuProfile(numberMenu: number){
    switch(numberMenu){
      case 1: 
        return this.menuAdmin = [
          this.createHeaderItem('Admin', [
            this.createMenuItem('Tu perfil', 'pi pi-fw pi-home', ['/web/my-profile'])
          ]),
          this.createHeaderItem('Empresa', [
            this.createMenuItem('Perfil Empresa', 'pi pi-fw pi-cloud', ['/web/services/cloud']),
            this.createMenuItem('Gestion de Usuarios', 'pi pi-fw pi-shopping-cart', ['/web/services/ecommerce']),
          ]),
          this.createHeaderItem('Comunicación', [
            this.createMenuItem('Notificaciones', 'pi pi-fw pi-cloud', ['/web/services/cloud']),
            this.createMenuItem('Chat', 'pi pi-fw pi-cloud', ['/web/services/cloud']),
            this.createMenuItem('Contacto', 'pi pi-fw pi-cloud', ['/web/services/cloud']),
          ]),
          this.createHeaderItem('Soporte', [
            this.createMenuItem('Tickets', 'pi pi-fw pi-database', ['/web/services/hosting/data-base'])
          ]),
          this.createHeaderItem('', [
            this.createMenuItem('Salir', 'pi pi-fw pi-database', ['/web/services/hosting/data-base'])
          ])
        ];
      case 2:
        return this.menuProvider = [
          this.createHeaderItem('Usuario', [
            this.createMenuItem('Tu perfil', 'pi pi-fw pi-home', ['/web/my-profile'])
          ]),
          this.createHeaderItem('Empresa', [
            this.createMenuItem('Perfil Empresa', 'pi pi-fw pi-cloud', ['/web/services/cloud']),
            this.createMenuItem('Gestion de Usuarios', 'pi pi-fw pi-shopping-cart', ['/web/services/ecommerce']),
          ]),
          this.createHeaderItem('Comunicación', [
            this.createMenuItem('Notificaciones', 'pi pi-fw pi-cloud', ['/web/services/cloud']),
            this.createMenuItem('Chat', 'pi pi-fw pi-cloud', ['/web/services/cloud']),
            this.createMenuItem('Contacto', 'pi pi-fw pi-cloud', ['/web/services/cloud']),
          ]),
          this.createHeaderItem('Soporte', [
            this.createMenuItem('Tickets', 'pi pi-fw pi-database', ['/web/services/hosting/data-base'])
          ]),
          this.createHeaderItem('', [
            this.createMenuItem('Salir', 'pi pi-fw pi-database', ['/web/services/hosting/data-base'])
          ])
        ];
      case 3:
        return this.menuCustomer = [
          this.createHeaderItem('Usuario', [
            this.createMenuItem('Tu perfil', 'pi pi-fw pi-home', ['/web/my-profile'])
          ]),
          this.createHeaderItem('Empresa', [
            this.createMenuItem('Perfil Empresa', 'pi pi-fw pi-cloud', ['/web/services/cloud']),
            this.createMenuItem('Gestion de Usuarios', 'pi pi-fw pi-shopping-cart', ['/web/services/ecommerce']),
          ]),
          this.createHeaderItem('Comunicación', [
            this.createMenuItem('Notificaciones', 'pi pi-fw pi-cloud', ['/web/services/cloud']),
            this.createMenuItem('Chat', 'pi pi-fw pi-cloud', ['/web/services/cloud']),
            this.createMenuItem('Contacto', 'pi pi-fw pi-cloud', ['/web/services/cloud']),
          ]),
          this.createHeaderItem('Soporte', [
            this.createMenuItem('Tickets', 'pi pi-fw pi-database', ['/web/services/hosting/data-base'])
          ]),
          this.createHeaderItem('', [
            this.createMenuItem('Salir', 'pi pi-fw pi-database', ['/web/services/hosting/data-base'])
          ])
        ];
      case 4:
        return this.menuUser = [
          this.createHeaderItem('menuUserUsuario', [
            this.createMenuItem('Tu perfil', 'pi pi-fw pi-home', ['/web/my-profile'])
          ]),
          this.createHeaderItem('Empresa', [
            this.createMenuItem('Perfil Empresa', 'pi pi-fw pi-cloud', ['/web/services/cloud']),
            this.createMenuItem('Gestion de Usuarios', 'pi pi-fw pi-shopping-cart', ['/web/services/ecommerce']),
          ]),
          this.createHeaderItem('Comunicación', [
            this.createMenuItem('Notificaciones', 'pi pi-fw pi-cloud', ['/web/services/cloud']),
            this.createMenuItem('Chat', 'pi pi-fw pi-cloud', ['/web/services/cloud']),
            this.createMenuItem('Contacto', 'pi pi-fw pi-cloud', ['/web/services/cloud']),
          ]),
          this.createHeaderItem('Soporte', [
            this.createMenuItem('Tickets', 'pi pi-fw pi-database', ['/web/services/hosting/data-base'])
          ]),
          this.createHeaderItem('', [
            this.createMenuItem('Salir', 'pi pi-fw pi-database', ['/web/services/hosting/data-base'])
          ])
        ];
      case 5:
        return this.menuGuest = [
          this.createHeaderItem('menuGuestUsuario', [
            this.createMenuItem('Tu perfil', 'pi pi-fw pi-home', ['/web/my-profile'])
          ]),
          this.createHeaderItem('Empresa', [
            this.createMenuItem('Perfil Empresa', 'pi pi-fw pi-cloud', ['/web/services/cloud']),
            this.createMenuItem('Gestion de Usuarios', 'pi pi-fw pi-shopping-cart', ['/web/services/ecommerce']),
          ]),
          this.createHeaderItem('Comunicación', [
            this.createMenuItem('Notificaciones', 'pi pi-fw pi-cloud', ['/web/services/cloud']),
            this.createMenuItem('Chat', 'pi pi-fw pi-cloud', ['/web/services/cloud']),
            this.createMenuItem('Contacto', 'pi pi-fw pi-cloud', ['/web/services/cloud']),
          ]),
          this.createHeaderItem('Soporte', [
            this.createMenuItem('Tickets', 'pi pi-fw pi-database', ['/web/services/hosting/data-base'])
          ]),
          this.createHeaderItem('', [
            this.createMenuItem('Salir', 'pi pi-fw pi-database', ['/web/services/hosting/data-base'])
          ])
        ];
      default:
        return this.menuGuest = [
          this.createHeaderItem('menuGuestDefaultUsuario', [
            this.createMenuItem('Tu perfil', 'pi pi-fw pi-home', ['/web/my-profile'])
          ]),
          this.createHeaderItem('Empresa', [
            this.createMenuItem('Perfil Empresa', 'pi pi-fw pi-cloud', ['/web/services/cloud']),
            this.createMenuItem('Gestion de Usuarios', 'pi pi-fw pi-shopping-cart', ['/web/services/ecommerce']),
          ]),
          this.createHeaderItem('Comunicación', [
            this.createMenuItem('Notificaciones', 'pi pi-fw pi-cloud', ['/web/services/cloud']),
            this.createMenuItem('Chat', 'pi pi-fw pi-cloud', ['/web/services/cloud']),
            this.createMenuItem('Contacto', 'pi pi-fw pi-cloud', ['/web/services/cloud']),
          ]),
          this.createHeaderItem('Soporte', [
            this.createMenuItem('Tickets', 'pi pi-fw pi-database', ['/web/services/hosting/data-base'])
          ]),
          this.createHeaderItem('', [
            this.createMenuItem('Salir', 'pi pi-fw pi-database', ['/web/services/hosting/data-base'])
          ])
        ];
    }
  }

  getMainMenuItems(): MenuItem[] {
    
    const userData = JSON.parse(localStorage.getItem('user-customer')!);
    const profileToMenuMapping: { [key: string]: number } = {
      Admin: environment.profileAdmin,
      Provider: environment.profileProvider,
      Customer: environment.profileCustomer,
      User: environment.profileUser,
      Guest: environment.profileGuest
    };
    
    const userProfile = userData.profile;
    const profileId = profileToMenuMapping[userProfile] || 5;

    return this.getMainMenuProfile(profileId)
  }

  getSecundaryMenuItems(): MenuItem[] {
    
    const userData = JSON.parse(localStorage.getItem('user-customer')!);
    const profileToMenuMapping: { [key: string]: number } = {
      Admin: environment.profileAdmin,
      Provider: environment.profileProvider,
      Customer: environment.profileCustomer,
      User: environment.profileUser,
      Guest: environment.profileGuest
    };

    // const profileToMenuEnglishMapping: { [key: string]: number } = {
    //   Admin: environment.profileAdmin,
    //   Provider: environment.profileProvider,
    //   Customer: environment.profileCustomer,
    //   User: environment.profileUser,
    //   Guest: environment.profileGuest
    // };
    
    const userProfile = userData.profile;
    const profileId = profileToMenuMapping[userProfile] || 5;

    return this.getSecundaryMenuProfile(profileId)
  }
}
