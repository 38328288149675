import { Component, OnInit } from '@angular/core';
import { MenuRoutesService } from '../../../developsoft-web/services/shared/menu-routes/menu-routes.service';
import { WebLayoutService } from '../../services/web-layout.service';

@Component({
  selector: 'app-web-menu',
  templateUrl: './web-menu.component.html',
})
export class WebMenuComponent implements OnInit{
  model: any[] = [];
  model2: any[] = [];

  constructor(public layoutService: WebLayoutService, public menuRoutesService: MenuRoutesService) { }

  ngOnInit() {
    this.model = this.menuRoutesService.getMainMenuItems();
  }
}
