import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  
  title = 'DevelopSoft';

  constructor(private primengConfig: PrimeNGConfig) { }

  date: Date | undefined | null;
  invalidDates: Array<Date> | undefined | null;
  locale: any;
  labels: any;
  displayDialog: boolean = false;
  ngOnInit() {
      this.primengConfig.ripple = true;
      this.setLanguage('es');

      const today = new Date();
      const invalidDate = new Date();
      invalidDate.setDate(today.getDate() - 1);
      this.invalidDates = [invalidDate];
    }
  
    setLanguage(language: string) {
      if (language === 'es') {
        this.locale = {
          firstDayOfWeek: 1,
          dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
          dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
          dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
          monthNames: ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"],
          monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"],
          today: 'Hoy',
          clear: 'Limpiar'
        };
  
        this.labels = {
          accept: 'Aceptar',
          cancel: 'Cancelar',
          showDialog: 'Mostrar Diálogo',
          dialogTitle: 'Título',
          dialogContent: 'Contenido del Diálogo'
        };
      } else if (language === 'en') {
        this.locale = {
          firstDayOfWeek: 0,
          dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
          dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
          dayNamesMin: ["S", "M", "T", "W", "T", "F", "S"],
          monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
          monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
          today: 'Today',
          clear: 'Clear'
        };
  
        this.labels = {
          accept: 'Accept',
          cancel: 'Cancel',
          showDialog: 'Show Dialog',
          dialogTitle: 'Title',
          dialogContent: 'Dialog Content'
        };
      }
    }
  
    accept() {
      console.log("Accepted date:", this.date);
    }
  
    cancel() {
      console.log("Action cancelled");
      this.date = null;
    }
  
    dialogAccept() {
      console.log("Dialog accepted");
      this.displayDialog = false;
    }
  
    dialogCancel() {
      console.log("Dialog cancelled");
      this.displayDialog = false;
    }
}
    

