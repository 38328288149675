import { Component, Output, effect, inject } from '@angular/core';
import { Router } from '@angular/router';

import { ConfirmationService, MessageService } from 'primeng/api';

import { DialogConfirm } from '../../../developsoft-web/interfaces/layout/overlayDialog';

import { AuthService } from '../../../developsoft-web/services/auth/auth.service';
import { WebOverlayService } from '../../services/web-overlay.service';

@Component({
  selector: 'app-dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  styles: [
  ],
  providers: [ConfirmationService, MessageService]
})
export class DialogConfirmComponent {


  public confirmDialogData: DialogConfirm = {
    message: '¿Estás seguro de que quieres continuar?',
    key: 'DialogConfirm',
    icon: 'pi pi-exclamation-triangle',
    position: 'center',
    header: 'Confirmación',
    acceptLabel: 'Sí',
    rejectLabel: 'No',
    acceptIcon: 'pi pi-check',
    rejectIcon: 'pi pi-times',
    acceptVisible: true,
    rejectVisible: true
  };
  @Output() respuesta = false;
  
  private dialogConfirmService = inject( WebOverlayService );

  private authService = inject( AuthService );
  private router      = inject( Router )


  constructor ( public webOverlayService: WebOverlayService) {
  }

  public dialogConfirmChargeDataEffect = effect(() => {
    this.confirmDialogData = this.dialogConfirmService.dialogConfirm()
    
  });

  get visible(): boolean {
    return this.webOverlayService.state.dialogConfirmActive;
  }

  set visible(_val: boolean) {
      this.webOverlayService.state.dialogConfirmActive = _val;
  }

  dialogiAccept() {
    switch(this.webOverlayService.type.dialogConfirmType){
      case 1:
        const sub = this.authService.logout().subscribe({
            next: ():void  => {
              //this.showSuccessViaToast();
              
              this.webOverlayService.state.dialogConfirmActive = false;
              this.router.navigateByUrl('/auth/login');
              sub.unsubscribe();
          },
          error: (message) => {
              console.log('funcion login: ', JSON.stringify(message));
          }
        });
        return true;
      case 2: 
        return false;
      default: 
        return false
    }
  }

  dialogiReject() {
    return false
  }
}
