export const environment = {
  production: true,

  // Routes
  developsoftAPI: 'https://developsoft-api.developsoft.cl/api',
  developsoftLandingAPI: 'https://developsoft-api.developsoft.cl/api/landing',
  page: 'https://developsoft.cl',
  ipapi: 'https://ipapi.co/json/',
  
  // Variables
  profileAdmin: 1,
  profileProvider: 2,
  profileCustomer: 3,
  profileUser: 4,
  profileGuest: 5,
  profileLanding: 6,

  // Conections
  recaptcha: {
    siteKey: '6LdSsBEnAAAAAPF0r9-cxaIvL0YwTXvCxHGVLwbP',
  },
  clientCredential: 'n7GcIzwT4ZSfgozpfoGStEkf9QYKoNGI1tUpZUVd'
};