import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, computed, inject, signal } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription, catchError, map, of, throwError } from 'rxjs';

import { environment } from 'src/environments/environment';
import { UserTransformDataPipe } from '../../pipes/data/user-transform-data.pipe';

import { AuthStatus, ResponseGlobal, UrlCheck, User } from '../../interfaces/auth';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly baseUrl: string = environment.developsoftAPI;
  private _http = inject( HttpClient );
  private _router = inject( Router );

  private _currentUser  = signal<User|null>(null);
  private _authStatus   = signal<AuthStatus>( AuthStatus.checking );
  private _urlCheck   = signal<UrlCheck>( UrlCheck.url );

  private subscriptions: Subscription[] = [];

  //! Al mundo exterior
  public currentUser  = computed( () => this._currentUser() );
  public authStatus   = computed( () => this._authStatus() );
  public urlCheck   = computed( () => this._urlCheck() );
  

  // LocalStorage
  private _token?: string  = '';
  public _rememberme: boolean = false;

  constructor(private userTransformDataPipe: UserTransformDataPipe) {
    this.checkAuthStatus().subscribe();
  }

  private setAuthentication(user: User, token?:string, tokenExpiresAt?:string): boolean {
    this._currentUser.set( user );
    this._authStatus.set( AuthStatus.authenticated );

    this.rememberMe(user?.name, user?.email);
 
    localStorage.setItem('user-customer', JSON.stringify(this.userTransformDataPipe.transform(user)))

    if(token){
      localStorage.setItem('tkn', token);
    }
    if(tokenExpiresAt){
      localStorage.setItem('ext', tokenExpiresAt);
    }
    return true;
  }

  login( email: string, password: string): Observable<boolean> {

    const url  = `${ this.baseUrl }/login/login`;
    const body = { email, password };

    return this._http.post<ResponseGlobal>( url, body )
      .pipe(
        // map( ({ user, token }) => this.setAuthentication( user, token )),
        map( ({ data }) => this.setAuthentication( data?.[0].user, data?.[1].token )),
        catchError( err => throwError( () => err ))
      );
  }
  logout(){

    const url   = `${ this.baseUrl }/user/logout`;
    const token = localStorage.getItem('tkn');
    
    const headers = new HttpHeaders()
      .set('Authorization', `Bearer ${ token }`);

    return this._http.get<ResponseGlobal>(url, { headers })
    .pipe(
      map( ({ data }) => 
        this.successLogout()
      ),
      catchError(() => {
        return of(false);
      })
    );
  }

  // Save subscription
  saveUnsubcriptions(sub: Subscription){
    this.subscriptions.push(sub)
  }
  // Clear Logout
  successLogout(){
    localStorage.removeItem('tkn');
    localStorage.removeItem('user-customer');
    localStorage.removeItem('ext');
    this._currentUser.set(null);
    this._authStatus.set( AuthStatus.unAuthenticated );
    this.ngOnDestroySubscriptions();
    return true
  }
  // Clear all subscription
  ngOnDestroySubscriptions(){
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  //Token Check
   checkAuthStatus():Observable<boolean> {

    const url   = `${ this.baseUrl }/user/check-token`;
    const token =  this.getToken();

    if ( !token ) {
      this.logout();
      return of(false);
    }

    const headers = new HttpHeaders()
      .set('Authorization', `Bearer ${ token }`);

    return this._http.get<ResponseGlobal>(url, { headers })
      .pipe(
        map( ({ data }) => this.setAuthentication( data?.[0].user, token ,data?.[1].tokenExpiresAt)),
        catchError(() => {
          //console.log('no validate check token', token);
          this._authStatus.set( AuthStatus.unAuthenticated );
          return of(false);
        })
      );
  }
  
  //Register
  signup( email: string, password: string): Observable<boolean> {

    const url  = `${ this.baseUrl }/login`;
    const body = { email, password };

    return this._http.post<ResponseGlobal>( url, body )
      .pipe(
        // map( ({ user, token }) => this.setAuthentication( user, token )),
        map( ({ data }) => this.setAuthentication( data?.[0].user, data?.[1].token )),
        catchError( err => throwError( () => err ))
      );
  }

  // Remember Interface Login
  rememberMe(_name: string, _email: string){
    let auxRememberme =  localStorage.getItem('remember-me');
    let userRembermer = {
      'name': _name,
      'email': _email
    };
    if(auxRememberme==='true'){
      localStorage.setItem('remember-user', JSON.stringify(userRembermer));
    }
    if(auxRememberme==='false'){
      localStorage.removeItem('remember-user');
    }

  }

  // Get Local
  getToken(){
    return localStorage.getItem('tkn');
  }
  getProfile(){
    return localStorage.getItem('token');
  }
  getRole(){
  }

  // Support - Debug
  debugPrintData(data: any){
    return console.log('Debug data: '+ JSON.stringify(data));
  }
  
  
}
