import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';

import { DialogConfirmComponent } from './dialog-confirm.component';

@NgModule({
  declarations: [
    DialogConfirmComponent
  ],
  imports: [
    CommonModule,
    ConfirmDialogModule,
    ToastModule
  ],
  exports: [
    DialogConfirmComponent
  ]
})
export class DialogConfirmModule { }
