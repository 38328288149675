import { Component, ElementRef } from '@angular/core';
import { WebLayoutService } from '../../services/web-layout.service';

@Component({
  selector: 'app-web-sidebar',
  templateUrl: './web-sidebar.component.html',
  styles: [
  ]
})
export class WebSidebarComponent {
  constructor(public WeblayoutService: WebLayoutService, public el: ElementRef) { }

}
