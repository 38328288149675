import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { InputSwitchModule } from 'primeng/inputswitch';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SidebarModule } from 'primeng/sidebar';

import { SidebarRightMenuitemComponents } from './sidebar-right-menuitem.component';
import { SidebarRightComponent } from './sidebar-right.component';



@NgModule({
  declarations: [
    SidebarRightComponent,
    SidebarRightMenuitemComponents
  ],
  imports: [
    CommonModule,
    FormsModule,
    SidebarModule,
    RadioButtonModule,
    ButtonModule,
    InputSwitchModule,
    RouterModule,
    DividerModule
  ],
  exports:[
    SidebarRightComponent
  ]
})
export class SidebarRightModule { }
