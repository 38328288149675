import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../../interfaces/auth';

@Pipe({
  name: 'userTransformData'
})
export class UserTransformDataPipe implements PipeTransform {

  // private menuAdmin = environment.profileAdmin;
  // private menuDevelopSoft = environment.profileDevelopsoft;
  // private menuCustomer = environment.profileCustomer;
  // private menuInvitado = environment.profileInvitado;

  private readonly profileMapping = {
    1: 'Admin',
    2: 'Provider',
    3: 'Customer',
    4: 'User',
    5: 'Guest'
  };

  private readonly userStateMapping = {
    1: 'Active',
    2: 'Inactive'
  };

  constructor(private datePipe: DatePipe) {}

  transform(user: User): User {
    if (!user) {
      return user;
    }

    //user.user = user.id;
    user.profile = this.profileMapping[user.profile as keyof typeof this.profileMapping] || 'Invitado';
    user.user_state = this.userStateMapping[user.user_state as keyof typeof this.userStateMapping] || 'Inactive';


    user.email_verified_at = this.formatDate(user.email_verified_at);
    user.created_at = this.formatDate(user.created_at);
    user.updated_at = this.formatDate(user.updated_at);

    delete user.id;
    delete user.login_type;
 
    return user;
  }

  private mapValue(value: number, mapping: Record<number, string>, defaultValue: string): string {
    return mapping[value] || defaultValue;
  }

  private formatDate(dateString: string): string {
    if (!dateString) {
      return '';
    }
    const formattedDate = this.datePipe.transform(dateString, 'dd-MM-yyyy, HH:mm:ss');
    return formattedDate || ''; // Si es null, devuelve una cadena vacía
  }

}
