import { Injectable, computed, signal } from '@angular/core';

import { DialogConfirm, OverlayDialog, OverlayDialogType } from '../../developsoft-web/interfaces/layout/overlayDialog';


@Injectable({
  providedIn: 'root'
})
export class WebOverlayService {

  configDialogDefault: DialogConfirm = {
    message: '¿Estás seguro de que quieres continuar?',
    key: 'DialogConfirm',
    position: 'center',
    icon: 'pi pi-exclamation-triangle',
    header: 'Confirmación',
    acceptLabel: 'Aceptar',
    rejectLabel: 'Cancelar',
    acceptIcon: 'pi pi-check',
    rejectIcon: 'pi pi-times',
    acceptVisible: true,
    rejectVisible: true
  };

  configDialogLogout: DialogConfirm = {
    message: '¿Estás seguro de que quieres salir?',
    key: 'DialogConfirm',
    position: 'center',
    icon: 'pi pi-exclamation-triangle',
    header: 'Confirmación',
    accept: () => {
      console.log('Aceptar')
    },
    reject: () => {
      console.log('Cancelar')
    },
    acceptLabel: 'Sí',
    rejectLabel: 'No',
    acceptIcon: 'pi pi-check',
    rejectIcon: 'pi pi-times',
    acceptVisible: true,
    rejectVisible: true
  };

  state: OverlayDialog = {
    dialogConfirmActive: false,
  }

  type: OverlayDialogType = {
    dialogConfirmType: 0
  }

  private _dialogConfirm = signal<DialogConfirm>(this.configDialogDefault);

  //exterior
  public dialogConfirm  = computed( () => this._dialogConfirm() );

  constructor() { }

  openDialogConfirm(typeDialog: number) {
    //this.setDataDialogConfirm(typeDialog);
    switch(typeDialog){
      case 1:
        this.type.dialogConfirmType = 1;
        this._dialogConfirm.set(this.configDialogLogout);
        this.state.dialogConfirmActive = true;
        return true;
      case 2: 
        this.type.dialogConfirmType = 2;
        this._dialogConfirm.set(this.configDialogDefault)
        this.state.dialogConfirmActive = true;
        return false;
      default: 
        this._dialogConfirm.set(this.configDialogDefault)
        this.state.dialogConfirmActive = true;
        return false

    }
  }

  // setDataDialogConfirm(typeDialog: number) {
  //   typeDialog
  //   return typeDialog
  // }
  // getDataDialogConfirm() {
  //   return this.configDialog
  // }
}
