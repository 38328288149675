import { DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';

import { NgModule } from '@angular/core';

import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';


// Demo
// import { NotfoundComponent } from './demo/components/notfound/notfound.component';
import { CountryService } from './demo/service/country.service';
import { CustomerService } from './demo/service/customer.service';
import { EventService } from './demo/service/event.service';
import { IconService } from './demo/service/icon.service';
import { NodeService } from './demo/service/node.service';
import { PhotoService } from './demo/service/photo.service';
import { ProductService } from './demo/service/product.service';

import { WebLayoutModule } from './layout-web/web-layout.module';
import { AppLayoutModule } from './layout/app.layout.module';

// Componet
import { ImageDetailComponent } from './developsoft-web/components/shared/image-detail/image-detail.component';
import { NoServicesComponent } from './developsoft-web/components/shared/no-services/no-services.component';
import { NotfoundComponent } from './landing/pages/not-found/notfound.component';


// Pipes
import { ContactTransformDataPipe } from './developsoft-web/pipes/data/contact-transform-data.pipe';
import { TranslateDataPipe } from './developsoft-web/pipes/data/translate-data.pipe';
import { UserTransformDataPipe } from './developsoft-web/pipes/data/user-transform-data.pipe';


// PRIMENG GENERAL

// envirotment

@NgModule({
    declarations: [
        AppComponent, NotfoundComponent, NoServicesComponent, TranslateDataPipe, ImageDetailComponent, ContactTransformDataPipe
    ],
    imports: [
        AppRoutingModule,
        BrowserModule,
        HttpClientModule,
        AppLayoutModule,
        WebLayoutModule,
    ],
    providers: [
        { provide: LocationStrategy, useClass: HashLocationStrategy }
        ,
        CountryService, CustomerService, EventService, IconService, NodeService,
        PhotoService, ProductService,
        UserTransformDataPipe, DatePipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
