import { Component, ElementRef, Input, OnInit } from '@angular/core';

import { MenuRoutesService } from '../../../developsoft-web/services/shared/menu-routes/menu-routes.service';
import { WebLayoutService } from '../../services/web-layout.service';

@Component({
  selector: 'app-sidebar-right',
  templateUrl: './sidebar-right.component.html',
  styles: [
  ]
})
export class SidebarRightComponent implements OnInit {

  @Input() minimal: boolean = false;

  model: any[] = [];

  constructor(public layoutService: WebLayoutService, public el: ElementRef, private menuRoutesService: MenuRoutesService) { }

  ngOnInit() {
    this.model = this.menuRoutesService.getSecundaryMenuItems();
}

  get visible(): boolean {
    return this.layoutService.state.rightSidebarVisible;
}

  set visible(_val: boolean) {
    this.layoutService.state.rightSidebarVisible = _val;
}

onSidebarRightButtonClick() {
  this.layoutService.showRightSidebar();
}

}