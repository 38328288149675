<p-sidebar [(visible)]="visible" position="right" [transitionOptions]="'.3s cubic-bezier(0, 0, 0.2, 1)'" styleClass="layout-right-sidebar p-sidebar-active w-20rem">
    <ng-template pTemplate="header" ><h3>Menú</h3></ng-template>
    <ng-template pTemplate="content">
        <ul class="layout-menu">
            <ng-container *ngFor="let item of model; let i = index;">
                <li app-sidebar-right-menuitem *ngIf="!item.separator" [item]="item" [indexRight]="i" [root]="true"></li>
                <li *ngIf="item.separator" class="menu-separator"></li>
                <p-divider type="solid" class="section-Divider"></p-divider>
            </ng-container>
            <!-- <li>
                <a href="https://www.primefaces.org/primeblocks-ng/#/">
                    <img src="assets/layout/images/{{layoutService.config.colorScheme === 'light' ? 'banner-logo-developsoft' : 'banner-logo-developsoft-dark'}}.png" alt="Prime Blocks" class="w-full mt-3"/>
                </a>
            </li> -->
        </ul>
    </ng-template>
    <ng-template pTemplate="footer"></ng-template>
    <!-- <ng-container *ngIf="!minimal">
        <h5>Tipo de Menú</h5>
        <div class="field-radiobutton">
            <p-radioButton name="menuMode" value="static" [(ngModel)]="menuMode" inputId="mode1"></p-radioButton>
            <label for="mode1">Fijo</label>
        </div>
        <div class="field-radiobutton">
            <p-radioButton name="menuMode" value="overlay" [(ngModel)]="menuMode" inputId="mode2"></p-radioButton>
            <label for="mode2">Esconder</label>
        </div>
    </ng-container> -->

</p-sidebar>