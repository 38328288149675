
<div class="layout-wrapper" [ngClass]="containerClass">
     <app-web-topbar></app-web-topbar>
    <div class="layout-sidebar">
        <app-web-sidebar></app-web-sidebar>
    </div>
    <div class="layout-main-container">
        <div class="layout-main">
            <h1 *ngIf="!finishedAuthCheck()">
                <app-loading></app-loading>
            </h1>
            <router-outlet *ngIf="finishedAuthCheck()"></router-outlet>
        </div>
        <app-web-footer></app-web-footer>
    </div>
    <app-dialog-confirm></app-dialog-confirm>
    <app-sidebar-right ></app-sidebar-right>
    <app-web-config></app-web-config>
    <div class="layout-mask"></div>
</div>