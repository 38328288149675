import { Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';

import { WebLayoutService } from '../../services/web-layout.service';

import { AuthService } from '../../../developsoft-web/services/auth/auth.service';
import { WebOverlayService } from '../../services/web-overlay.service';

import { User } from '../../../developsoft-web/interfaces/auth';

@Component({
  selector: 'app-web-topbar',
  templateUrl: './web-topbar.component.html',
  styles: [
  ],
  providers: [ConfirmationService, MessageService]
})
export class WebTopbarComponent implements OnInit {

  items!: MenuItem[];

  @ViewChild('menubutton') menuButton!: ElementRef;

  @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

  @ViewChild('topbarmenu') menu!: ElementRef;

  // DevelopSoft
  public user?: User;

  private authService = inject( AuthService );

  constructor(
    public layoutService: WebLayoutService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private webOverlayService: WebOverlayService
  ) { }

  ngOnInit() {
    this.cargarTopbar();
    const userData = JSON.parse(localStorage.getItem('user-customer')!);
    this.user = userData;
    
  }

  cargarTopbar() {
    
  }

  logout() {
    this.authService.logout()
  }
  
  confirmlogout() {
    this.webOverlayService.openDialogConfirm(1)
  }

  onSidebarRightButtonClick(){
    this.layoutService.showRightSidebar();
  }

}