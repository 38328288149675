<p-toast></p-toast>
<p-confirmDialog
    #cd [visible]="visible"
    (onHide)="visible = false"
    [style.width]="confirmDialogData.position === 'center' ? '80vw' : ''"
    [position]="confirmDialogData.position ?? 'center'"
    [message]="confirmDialogData.message"
    [header]="confirmDialogData.header"
    [icon]="confirmDialogData.icon"
    rejectButtonStyleClass="p-button justify-content-between"
    acceptButtonStyleClass="p-button justify-content-between">
    <ng-template pTemplate="footer" >
        <button type="button" pButton icon="pi pi-check" label="Sí" (click)="dialogiAccept()"></button>
        <button type="button" pButton icon="pi pi-times" label="No" (click)="cd.reject()"></button>
    </ng-template>
</p-confirmDialog>

<!-- <p-confirmDialog
    #cd [visible]="visible"
    (onHide)="visible = false"
    [style]="{width: '50vw'}"
    [position]="confirmDialogData.position ?? 'center'"
    [message]="confirmDialogData.message"
    [header]="confirmDialogData.header"
    [icon]="confirmDialogData.icon"
    [acceptVisible]="confirmDialogData.acceptVisible ?? true"
    [acceptIcon]="confirmDialogData.acceptIcon"
    [acceptLabel]="confirmDialogData.acceptLabel"
    [rejectVisible]="confirmDialogData.rejectVisible ?? true"
    [rejectIcon]="confirmDialogData.rejectIcon"
    [rejectLabel]="confirmDialogData.rejectLabel"
    rejectButtonStyleClass="p-button justify-content-between"
    acceptButtonStyleClass="p-button justify-content-between">
</p-confirmDialog> -->
