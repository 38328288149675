import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotfoundWebComponent } from './developsoft-web/components/notfound/layout-web/notfound-web.component';
import { NotfoundComponent } from './landing/pages/not-found/notfound.component';
// import { PrivateCheckAuthenticatedGuard } from './developsoft-web/guards';
import { WebLayoutComponent } from "./layout-web/web-layout.component";
// import { AppLayoutModule } from './layout/app.layout.module';

const routes: Routes = [
    // { path: '', redirectTo:'landing', pathMatch:'full'  },
    { path: '', redirectTo:'', pathMatch:'full'  },
    { 
        path: '', 
        loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule),
    },
    // {
    //     path: 'forms',
    //     loadChildren: () => import('./forms-google/google-form.module').then(c => c.GoogleFormsModule),
    // },
    { 
        path: 'v-card', 
        loadChildren: () => import('./developsoft-web/components/business-card/business-card.module').then(m => m.BusinessCardModule),
    },
    { 
        path: 'images/:id', 
        loadComponent: () => import('./developsoft-web/components/shared/image-detail/image-detail.component').then(c => c.ImageDetailComponent) 
    },
    {
        path: 'web', component: WebLayoutComponent,
        // canActivate: [ PrivateCheckAuthenticatedGuard ],
        children: [
            { path: '', loadChildren: () => import('./developsoft-web/components/dashboard/dashboard.module').then(m => m.DashboardModule) },
            { path: 'my-profile', loadChildren: () => import('./developsoft-web/components/shared/my-profile/my-profile.module').then(m => m.MyProfileModule) },
            { path: 'services', loadChildren: () => import('./developsoft-web/components/customer/services/services.module').then(m => m.ServicesModule) },
            { path: 'project', loadChildren: () => import('./demo/components/utilities/utilities.module').then(m => m.UtilitiesModule) },
            { path: 'software', loadChildren: () => import('./demo/components/pages/pages.module').then(m => m.PagesModule) },
            { path: 'notificaciones', loadChildren: () => import('./demo/components/utilities/utilities.module').then(m => m.UtilitiesModule) },
            { path: 'notfound', component: NotfoundWebComponent },
            { path: '**', redirectTo: '/web/notfound' },
        ],
    },
    // { 
    //     path: 'auth',
    //     //canActivate: [ PublicCheckAuthenticatedGuard ],
    //     loadChildren: () => import('./developsoft-web/components/auth/auth.module').then(m => m.AuthModule)
    // },
    { path: 'notfound', component: NotfoundComponent },
    { path: '**', redirectTo: '/notfound' },
]

@NgModule({
    imports: [
        RouterModule.forRoot( routes,
            { 
                useHash: true , 
                scrollPositionRestoration: 'enabled', 
                anchorScrolling: 'enabled', 
                onSameUrlNavigation: 'reload' }
        )
    ],
    exports: [RouterModule]
})

export class AppRoutingModule {
}
