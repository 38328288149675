import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

// primeng
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { BadgeModule } from 'primeng/badge';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RippleModule } from 'primeng/ripple';
import { SidebarModule } from 'primeng/sidebar';
import { ToastModule } from 'primeng/toast';

// modules
import { LoadingModule } from '../developsoft-web/components/shared/loading/loading.module';
import { DialogConfirmModule } from './base/dialog-confirm/dialog-confirm.module';
import { SidebarRightModule } from './base/sidebar-right/sidebar-right.module';
import { WebConfigModule } from './config/web-config.module';

// Services
// import { WebLayoutService } from './services/web-layout.service';
// import { WebMenuService } from './services/web.menu.service';

// componets
import { WebLayoutComponent } from './web-layout.component';
// Menu
import { NotificationsPopupComponent } from '../developsoft-web/components/shared/notifications/notifications-popup/notifications-popup.component';
import { WebMenuComponent } from './base/menu/web-menu.component';
import { WebMenuitemComponents } from './base/menu/web-menuitem.component';
// pages
import { WebContactComponent } from './base/contact/web-contact.component';
import { WebFooterComponent } from './base/footer/web-footer.component';
import { WebSidebarComponent } from './base/sidebar/web-sidebar.component';
import { WebTopbarComponent } from './base/topbar/web-topbar.component';



@NgModule({
    declarations: [
        WebLayoutComponent,
        WebContactComponent,
        WebFooterComponent,
        WebMenuComponent,
        WebMenuitemComponents,
        WebSidebarComponent,
        WebTopbarComponent,
        NotificationsPopupComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        InputTextModule,
        SidebarModule,
        BadgeModule,
        RadioButtonModule,
        InputSwitchModule,
        RippleModule,
        RouterModule,
        WebConfigModule,
        LoadingModule,
        SidebarRightModule,
        ToastModule,
        DialogConfirmModule,
        ConfirmDialogModule,
        AvatarModule,
        AvatarGroupModule,
        OverlayPanelModule
    ],
    exports: [ WebLayoutComponent ]
})
export class WebLayoutModule { }