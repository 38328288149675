<div class="col 12">
    <div class="surface-ground flex align-items-center justify-content-center overflow-hidden">
        <div class="flex flex-column align-items-center justify-content-center">
            <a class="flex align-items-center" href="#">
                <img src="assets/layout/images/{{layoutService.config.colorScheme === 'light' ? 'logo-developsoft' : 'logo-developsoft'}}.svg" 
                alt="DevelopSoft Logo" 
                height="40" >
            </a>
            <a class="flex align-items-center" href="#">
                <h1 class="text-900 font-medium text-900"></h1>
            </a>
            <div style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, rgba(29, 111, 219, 0.9) 40%, rgba(33, 150, 243, 0) 60%);">
                <div class="w-full surface-card py-8 px-5 sm:px-8 flex flex-column align-items-center" style="border-radius:53px">
                    <span class="text-blue-600 font-bold text-3xl">404</span>
                    <h1 class="text-900 font-bold text-3xl lg:text-5xl mb-2">No encontrado</h1>
                    <div class="text-600 mb-5">El recurso solicitado no está disponible</div>
                    <a class="w-full flex align-items-center py-5 border-300 border-bottom-1">
                        <span class="flex justify-content-center align-items-center bg-blue-600 border-round" style="height:3.5rem; width:3.5rem;">
                            <i class="text-50 pi pi-fw pi-question-circle text-2xl"></i>
                        </span>
                        <span class="ml-4 flex flex-column">
                            <span class="text-900 lg:text-xl font-medium mb-0 block">Preguntas frecuentes</span>
                            <span class="text-600 lg:text-xl">Encuentra respuestas a nuestras soluciones.</span>
                        </span>
                    </a>
                    <a class="w-full flex align-items-center py-5 border-300 border-bottom-1">
                        <span class="flex justify-content-center align-items-center bg-orange-600 border-round" style="height:3.5rem; width:3.5rem;">
                            <i class="pi pi-fw pi-users text-50 text-2xl"></i>
                        </span>
                        <span class="ml-4 flex flex-column">
                            <span class="text-900 lg:text-xl font-medium mb-0">Centro de Soluciones</span>
                            <span class="text-600 lg:text-xl">Servicios a tu disposición.</span>
                        </span>
                    </a>
                    <a class="w-full flex align-items-center mb-5 py-5 border-300 border-bottom-1">
                        <span class="flex justify-content-center align-items-center bg-indigo-700 border-round" style="height:3.5rem; width:3.5rem;">
                            <i class="pi pi-fw pi-whatsapp text-50 text-2xl"></i>
                        </span>
                        <span class="ml-4 flex flex-column">
                            <span class="text-900 lg:text-xl font-medium mb-0">Informanos</span>
                            <span class="text-600 lg:text-xl">Escribenos a soporte</span>
                        </span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>